<template>
  <div>
    <br /><br /><br /><br /><br />
    <v-container fluid>
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-filter</v-icon>Filters
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2"> Filters </v-card-title>

          <v-card-text>
            <h4>Payment methods</h4>
            <v-divider></v-divider>
            <v-checkbox
              v-model="bitpay"
              label="BitPay"
              value="bitpay"
            ></v-checkbox>
            <v-checkbox
              v-model="paypal"
              label="Paypal"
              value="paypal"
            ></v-checkbox>
            <h4>Status</h4>
            <v-divider></v-divider>

            <v-checkbox
              v-model="failed"
              label="Failed"
              value="Failed"
            ></v-checkbox>
            <v-checkbox
              v-model="successful"
              label="Successful"
              value="Successful"
            ></v-checkbox>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="(dialog = false), filteringData()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card>
        <!-- <v-simple-table fixed-header>
          <thead>
            <tr>
              <th
                class="text-left"
                style="
                  font-size: 20px;
                  font-weight: 500px;
                  color: black;
                  background-color: white;
                "
              >
                Tours
              </th>
              <br />
              <th
                class="text-left"
                style="
                  font-size: 20px;
                  font-weight: 500px;
                  color: black;
                  background-color: white;
                "
              >
                Booking Date
              </th>
              <th
                class="text-left"
                style="
                  font-size: 20px;
                  font-weight: 500px;
                  color: black;
                  background-color: white;
                "
              >
                Status
              </th>
              <th
                class="text-left"
                style="
                  font-size: 20px;
                  font-weight: 500px;
                  color: black;
                  background-color: white;
                "
              >
                Package id
              </th>
              <th
                class="text-left"
                style="
                  font-size: 16px;
                  font-weight: 500px;
                  color: black;
                  background-color: white;
                "
              >
                Paid By
              </th>
              <th
                class="text-left"
                style="
                  font-size: 20px;
                  font-weight: 500px;
                  color: black;
                  background-color: white;
                "
              >
                Total Price
              </th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in orderDetails" v-bind:key="index">
            <tr>
              <td>{{ item.package.package_title }}</td>

              <br />

              <td>{{ convertDate(item.created_at) }}</td>

              <td v-if="item.is_paid === true">
                <v-icon color="green"
                  >mdi-checkbox-multiple-marked-circle</v-icon
                >Successful
              </td>
              <td v-if="item.is_paid === false">
                <v-icon color="red"> mdi-close-circle</v-icon>Failed
              </td>

              <td>{{ item._id }}</td>
              <td>{{ item.payment_provider }}</td>
              <td>${{ item.total_package_price }}</td>
            </tr>
          </tbody>
        </v-simple-table> -->
        <v-data-table
          :headers="headers"
          :items="orderDetails"
          :items-per-page="5"
          class="elevation-1"
          :loading="loading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.paymentStatus`]="{ item }">
            <v-icon color="green" v-if="item.is_paid"
              >mdi-checkbox-multiple-marked-circle</v-icon
            >
            <v-icon color="red" v-else> mdi-close-circle</v-icon>
            {{ item.paymentStatus }}
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      bitpay: false,
      paypal: false,
      failed: false,
      successful: false,
      filterflag: false,
      storedOrderDetails: [],
      paypalResult: '',
      headers: [
        { text: 'Tour', value: 'package.package_title' },
        { text: 'Booking Date', value: 'paymentDate' },
        { text: 'Status', value: 'paymentStatus' },

        { text: 'Paid By', value: 'payment_provider' },
        { text: 'Total Price', value: 'total_package_price' },
      ],
      orderDetails: [],
    };
  },
  created() {
    this.fetchOrderList();
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    filteringData() {
      if (this.paypal) {
        this.orderDetails = this.storedOrderDetails.filter(
          (paymentStatus) => paymentStatus.payment_provider == this.paypal
        );
        return;
      }
      if (this.bitpay) {
        this.orderDetails = this.storedOrderDetails.filter(
          (paymentStatus) => paymentStatus.payment_provider == this.bitpay
        );
        return;
      }

      if (this.successful) {
        this.orderDetails = this.storedOrderDetails.filter(
          (paymentStatus) => paymentStatus.paymentStatus == this.successful
        );
        return;
      }

      if (this.failed) {
        this.orderDetails = this.storedOrderDetails.filter(
          (paymentStatus) => paymentStatus.paymentStatus == this.failed
        );
        return;
      }
      this.orderDetails = this.storedOrderDetails;
    },
    async fetchOrderList() {
      let orderDetails = [];
      this.loading = true;
      this.orderDetails = [];
      try {
        const res = await this.$http.get('getBookingHistory/' + this.user._id);

        orderDetails = res.data.map((order) => {
          order.paymentStatus = order.is_paid ? 'Successful' : 'Failed';
          order.paymentDate = this.$moment
            .unix(order.created_at)
            .format('llll');
          return order;
        });
        this.orderDetails = orderDetails;
        this.storedOrderDetails = orderDetails;

        console.log('order details', this.orderDetails);
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
  },
};
</script>
